<template>
  <div class="w-full h-full pt-2">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 mr-8">Settings</h1>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div class="mx-3">
      <Card class="mt-2 p-5">
        <p class="text-lg font-bold">Transaction History</p>
        <div class="w-full border" style="height: 0px" />
        <card class="p-5 mt-4">
          <card-footer reloadcard class="mt-1" />
        </card>

        <div style="height: 100%" class="mt-28" v-if="loader">
          <loader size="xxs" :loader-image="false" />
        </div>

        <div class="w-full mt-4" v-else>
          <div
            class="w-full gap-x-12 gap-y-5 mb-6 flex flex-col justify-center items-center"
            v-if="accountsData.length === 0"
          >
            <div class="mt-4 w-full flex py-6 justify-center">
              <img src="@/assets/images/Wallet-empty.png" alt="" />
            </div>
            <p class="text-base w-full flex justify-center">
              You’ve made no transactions in the past. Make transactions to see
              your history.
            </p>
          </div>

          <Table
            :headers="accountsHeader"
            :items="accountsData"
            class="w-full p-1 mb-4"
            aria-label="Transaction history"
            :has-number="true"
            :pagination-list="metaData"
            @page="handlePage($event)"
            page-sync
            @itemsPerPage="handleItemsPerPage($event)"
            v-else
          >
            <template v-slot:item="{ item }">
              <div v-if="item.paidAt">
                <div class="flex flex-col">
                  <p>
                    {{
                      $DATEFORMAT(
                        new Date(item.data.createdAt),
                        "MMMM dd, yyyy"
                      )
                    }}
                  </p>
                  <p class="text-xs text-blueCrayola">
                    {{ $DATEFORMAT(new Date(item.data.createdAt), "hh:mm a") }}
                  </p>
                </div>
              </div>
              <div v-else-if="item.type" class="capitalize">
                {{ item.data.type }}
              </div>
              <div v-else-if="item.isSettled">
                <Badge
                  v-if="item.data.isSettled"
                  label="Success"
                  variant="primary"
                  background-color="rgba(19, 181, 106, 0.08)"
                  color="#13B56A"
                  style="padding: 9px; height: 33px; font-weight: 600"
                />
                <Badge
                  v-else
                  label="Pending"
                  variant="primary"
                  background-color="rgba(233, 147, 35, 0.08)"
                  color="rgba(233, 147, 35, 1)"
                  style="padding: 9px; height: 33px; font-weight: 600"
                />
              </div>
              <div v-else-if="item.amount">
                <span v-if="item.data.type === 'debit'" class="text-desire">
                  ({{ convertToCurrency(item.data.transferedAmount) }})
                </span>
                <span v-else>
                  {{ convertToCurrency(item.data.transferedAmount) }}
                </span>
              </div>
              <div v-else-if="item.charges" class="text-desire">
                ({{ convertToCurrency(item.data.charges) }})
              </div>
              <div v-else-if="item.balance">
                {{ convertToCurrency(item.data.balance) }}
              </div>
            </template>
          </Table>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";

export default {
  components: {
    Breadcrumb,
    BackButton,
    Card,
    CardFooter,
    Table,
    Badge,
  },
  data() {
    return {
      loader: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Bank Accounts",
          id: "Bank Accounts",
        },
      ],
      accountsHeader: [
        { title: "Date & Time", value: "paidAt" },
        { title: "Type", value: "type" },
        { title: "Description", value: "description" },
        { title: "Status", value: "isSettled" },
        { title: "Amount", value: "amount" },
        { title: "Processing Fee", value: "charges" },
        { title: "Account Balance", value: "balance" },
      ],
      accountsData: [],
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
    };
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `?page=${this.numberOfPage}`
        : `?page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.transactionHistory(`${pageNumber}${itemPage}`);
    },

    transactionHistory(params) {
      this.loader = true;
      this.$_nubanTransactionHistory(
        { nubanId: this.$route.params.id },
        params
      ).then((result) => {
        this.accountsData = result.data.data;
        this.metaData = result.data.meta;
        this.loader = false;
      });
    },
  },

  mounted() {
    this.transactionHistory("?page=1&perPage=50");
  },
};
</script>
